/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.app-main-footer {
  display: flex;
  align-items: center;
  padding: 5.5px 20px;
  color: #111827;
  background-color: #fff;
}
@media screen and (min-width: 768px) {
  .app-main-footer {
    padding: 5.5px 32px;
  }
}
.app-main-footer p {
  margin-bottom: 0;
}
.app-main-footer .row {
  height: 100%;
  width: 100%;
}
.app-main-footer .row .ant-col {
  margin-bottom: 0 ;
}
.footer-btn-view {
  margin-left: auto;
}
[dir=rtl] .footer-btn-view {
  margin-left: 0;
  margin-right: auto;
}
.footer-btn {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  height: auto;
}
