.ant-modal-content {
  border-radius: 10px !important;
}

.ant-modal-body {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  flex-direction: column !important;
  align-items: center !important;
}

.AddDialog .ant-modal-close-x {
  display: none;
}

.ant-modal-body p.title {
  font-size: 18px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 600 !important;
}

.ant-modal-body .ant-col:first-child {
  text-align: center;
}

.ant-modal-footer {
  border: none !important;
  display: flex;
  padding-bottom: 30px !important;
  justify-content: space-evenly;
  flex-direction: row-reverse;
}

.ant-modal-footer button {
  width: 124px;
  height: 42px;
  border-radius: 10px;
  opacity: 1;
  padding: 0;
  font-size: 16px !important;
}