/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-row > .ant-col {
  margin-bottom: 16px;
}
@media screen and (min-width: 768px) {
  .ant-row > .ant-col {
    margin-bottom: 0px;
  }
}
.ant-form .ant-row > .ant-col {
  margin-bottom: 0;
}
.ant-row.ant-form-item > .ant-col {
  margin-bottom: 0;
}
