/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
[data-simplebar] {
  position: relative;
  width: 100%;
}
[data-simplebar] .simplebar-offset,
[data-simplebar] .simplebar-content-wrapper,
[data-simplebar] .simplebar-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
[data-simplebar] .simplebar-offset ul,
[data-simplebar] .simplebar-content-wrapper ul,
[data-simplebar] .simplebar-content ul {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
[data-simplebar] .simplebar-offset ul li,
[data-simplebar] .simplebar-content-wrapper ul li,
[data-simplebar] .simplebar-content ul li {
  padding-left: 15px !important ;
}
[data-simplebar] .simplebar-offset .ant-pagination li,
[data-simplebar] .simplebar-content-wrapper .ant-pagination li,
[data-simplebar] .simplebar-content .ant-pagination li {
  padding-left: 0 !important ;
}
