/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.app-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.app-logo img {
  height: 100px;
  margin-right: 10px;
}
[dir="rtl"] .app-logo img {
  margin-right: 0;
  margin-left: 10px;
}
.app-logo-name {
  font-weight: 500;
  font-size: 24px;
  display: none;
}
@media screen and (min-width: 576px) {
  .app-logo-name {
    display: block;
  }
}
.app-logo-name.white {
  color: #fff;
}
.app-title-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 20px;
  color: #002f6f;
  font-weight: bold;
}
.app-title-logo svg {
  width: 14rem;
}
