// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Montserrat:wght@500;600;800&family=Poppins:wght@500;600;700;800&display=swap");

:root {
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 600;
  --extra-bold: 700;
  font-family: "Montserrat", sans-serif;
}

body {
  line-height: 1.35 !important;
  font-family: "Montserrat", sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: @font-size-lg;
}

h5 {
  font-size: @font-size-base;
}

h6 {
  font-size: @font-size-base;
}
