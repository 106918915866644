/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.notify-btn-all {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  text-transform: capitalize;
}
.notify-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 24px;
  padding: 2px 12px 18px 12px;
  color: #111827;
}
.notify-link:hover,
.notify-link:focus {
  color: #111827;
  background-color: transparent;
}
@media screen and (min-width: 768px) {
  .notify-link {
    font-weight: 500;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px;
    line-height: 1;
  }
}
.notify-icon {
  position: relative;
  display: none;
  font-size: 2rem;
}
@media screen and (min-width: 768px) {
  .notify-icon {
    display: block;
  }
}
.notify-icon .count {
  position: absolute;
  top: -2px;
  right: -4px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 3px 0px;
  font-size: 0.8rem;
  min-width: 20px;
  transform: scale(1);
  transition: transform 0.3 ease;
  text-align: center;
}
.notify-text {
  font-size: 16px;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .notify-text {
    display: none;
  }
}
.notify-list {
  padding-top: 0;
  padding-bottom: 0;
}
.notify-scroll-submenu {
  height: 200px;
}
@media screen and (min-width: 1600px) {
  .notify-scroll-submenu {
    height: 380px;
  }
}
.notify-header-message {
  width: 260px;
  padding: 0;
}
@media screen and (min-width: 576px) {
  .notify-header-message {
    width: 300px;
  }
}
@media screen and (min-width: 1600px) {
  .notify-header-message {
    width: 380px;
  }
}
.notify-header-message .ant-dropdown-menu-item {
  padding: 0;
  white-space: normal;
}
.notify-header-message .ant-dropdown-menu-item:hover {
  background-color: transparent;
}
.notify-header-message .ant-dropdown-menu-item.header {
  padding: 12px 20px;
  font-weight: 500;
  cursor: inherit;
}
.notify-header-message .ant-dropdown-menu-item.header:hover,
.notify-header-message .ant-dropdown-menu-item.header:focus {
  background-color: transparent;
}
