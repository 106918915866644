/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.pageTitle h1 {
  font-size: 28px;
  letter-spacing: 0;
  color: #3d3d3d;
  font-weight: 600;
  margin-bottom: 30px;
}
.selectStatus {
  width: 100%;
}
.point {
  width: 10px;
  display: inline-block;
  height: 10px;
  background: #005ad5;
  border-radius: 50%;
}
.edit .title {
  color: #262626;
  font-weight: 600;
  font-size: 22px;
}
.edit .subTitle {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
}
.edit .col-title {
  display: flex;
  gap: 10px;
  align-items: baseline;
}
.edit .col-title h1 {
  font-weight: 600;
}
.edit .col-title .Go-back {
  border-color: #002F6F;
  border: 1px solid;
  color: #ffffff;
  padding-top: 9px;
  width: 40px;
  height: 40px;
  background: #002F6F;
  border-radius: 100px;
  opacity: 0.6;
  font-weight: bold;
}
.edit hr {
  width: 100%;
  margin: 20px 0;
  border: 1px solid #d4d4d4;
}
.edit .row-btn .ant-btn {
  margin: 0 10px;
}
.card-video .ant-card {
  overflow: hidden;
}
.card-video .ant-card-body {
  padding: 0;
}
.card-video .ant-modal-footer {
  display: none;
}
.ant-modal-close-x {
  display: block;
  width: 24px;
  height: 30px;
  font-size: 16px;
  font-style: normal;
  line-height: 33px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.ant-btn-link:hover {
  border-color: #14437d !important;
}
.card-video video {
  object-fit: cover;
}
